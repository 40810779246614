<template>
    <div>
        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl" />
            </div>
        </div>
        <div v-else class="box">
            <TabWires
                :wires="wires"
                @loadAsyncData="loadAsyncData"
                @validateWire="validateWire"
                @deleteWire="deleteWire"
                @addCredits="addCredits"
                @generateInvoice="generateInvoice"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Wires',
        data: () => ({
            wires: [],
            preloading: true
        }),
        computed: {},
        created() {
            this.getData()
        },
        mounted() {},
        methods: {
            getData: function () {
                this.axios
                    .get(`/admin/billing/wire-waiting`)
                    .then(response => {
                        this.wires = response.data.orders
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.preloading = false))
            },
            validateWire(params) {
                this.axios
                    .post(`/admin/billing/${params.wire_id}`, {
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.wires.data = this.wires.data.map(wire => {
                            if (wire.id === params.wire_id) {
                                wire.payment_status = 'complete'
                            }

                            return wire
                        })

                        this.snackNotif('Virement validé', 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif("Une erreur s'est produite !", 'is-danger')
                    })
                    .finally(() => {
                        params.callback()
                    })
            },

            addCredits(params) {
                this.axios
                    .post(`/admin/billing/add-credits/${params.wire_id}`, {
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.wires.data = this.wires.data.map(wire => {
                            if (wire.id === params.wire_id) {
                                if (wire.payment_status === 'in_progress_invoice') {
                                    wire.payment_status = 'complete'
                                } else {
                                    wire.payment_status = 'in_progress_credits'
                                }
                            }
                            return wire
                        })
                        this.snackNotif('Les crédits ont été ajoutés au client', 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif("Une erreur s'est produite !", 'is-danger')
                    })
                    .finally(() => {
                        params.callback()
                    })
            },

            generateInvoice(params) {
                this.axios
                    .post(`/admin/billing/generate-invoice/${params.wire_id}`, {
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.wires.data = this.wires.data.map(wire => {
                            if (wire.id === params.wire_id) {
                                if (wire.payment_status === 'in_progress_credits') {
                                    wire.payment_status = 'complete'
                                } else {
                                    wire.payment_status = 'in_progress_invoice'
                                }
                            }
                            return wire
                        })
                        this.snackNotif('La facture a été générée', 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif("Une erreur s'est produite !", 'is-danger')
                    })
                    .finally(() => {
                        params.callback()
                    })
            },

            deleteWire(params) {
                this.axios
                    .delete(`/admin/billing/${params.wire_id}`)
                    .then(() => {
                        this.wires.data = this.wires.data.filter(wire => wire.id !== params.wire_id)
                        this.snackNotif('Virement annulé', 'is-success')
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif("Une erreur s'est produite !", 'is-danger')
                    })
                    .finally(() => {
                        params.callback()
                    })
            },

            loadAsyncData(data) {
                var field = data.sortField

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                this.axios
                    .get(data.path, {
                        params
                    })
                    .then(response => {
                        this.wires = response.data.orders
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .finally(() => {
                        data.callback()
                    })
            }
        }
    }
</script>
